import styled from 'styled-components';
import {activeColor, fontColor} from "../../constants/theme";

export const ContainerCrt = styled.div`
position: relative;
  svg {
    font-size: 24px;
    float: right;
    cursor: pointer;
    color: ${activeColor};
    margin-top: -20px;
    position: absolute;
    top: 0px;
    right: 0px; 
  }
  @media (min-width: 320px) and (max-width: 480px) {
    svg {
      margin-top: 0;
    }
    h2 {
      margin-bottom: 10px !important;
    }
    h2.exclusion {
      margin-top: 10px;
    }
  }
   @media (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
     svg {
      top: 20px;
     }
   }
`;

export const CriteriaDiv = styled.div`
  padding: 0 20px;
  h2 {
    color: ${fontColor};
    font-size: 28px;
    margin: 20px 0;
    font-weight: bold;
    line-height: 36px;
  }
  pre {
    color: ${fontColor};
    font-size: 16px;
    line-height: 24px;
    border: none;
    white-space: pre-line;
    padding: 0;
    font-family: inherit;
    font-weight: 300;
    margin: 0px;
    padding-bottom: 10px;
    &.second-pre {
      padding-bottom: 20px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 20px 20px !important;
    h2 {
      font-size: 28px;
      line-height: 36px;
      display: inline-block;
      margin-top: 0;
      margin-bottom: 20px !important;
      &.exclusion {
        margin-top: 20px;
      }
    }
    pre {
      font-size: 16px;
      line-height: 24px;
      &.second-pre {
      padding-bottom: 0px;
    }
    }
  }
`;
