import React from 'react';
import PropTypes from 'prop-types';
import { WistiaStyle } from './wistia.css';

class WistiaVideo extends React.Component {
  constructor(props) {
    super(props);
    this.imgRef = null;
  }

  componentDidMount() {
    let { code } = this.props;
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.src = `https://fast.wistia.com/embed/medias/${code}.jsonp`;
    script1.async = true;

    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }

  render() {
    let { code, title, pageLoading } = this.props;
    /* eslint-disable */
    return (
      <WistiaStyle>
        <div
          className={'wistia_responsive_padding'}
          style={{ padding: '56.25% 0 0 0', position: 'relative' }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          >
            <div
              className={
                'wistia_embed wistia_async_' +
                code +
                ' seo=false videoFoam=true'
              }
              title={title}
              style={{ height: '100%', position: 'relative', width: '100%' }}
            >
              <div
                className="wistia_swatch"
                style={{
                  height: '100%',
                  left: 0,
                  top: 0,
                  position: 'absolute',
                  opacity: 0,
                  overflow: 'hidden',
                  transition: '0ms',
                  width: '100%',
                }}
              >
                <img
                  src={
                    'https://fast.wistia.com/embed/medias/' + code + '/swatch'
                  }
                  ref={element => (this.imgRef = element)}
                  style={{
                    filter: 'blur(5px)',
                    height: '100%',
                    objectFit: 'contain',
                    width: 'contain',
                  }}
                  alt=""
                  onLoad={() => {
                    this.imgRef.parentNode.style.opacity = 1;
                     if (typeof pageLoading !== 'undefined' && window.Wistia !== 'undefined')
                     { pageLoading();}
                  }}
                  title={title}
                  allowtransparency="true"
                />
              </div>
            </div>
          </div>
        </div>
      </WistiaStyle>
    );
    /* eslint-enable */
  }
}

WistiaVideo.propTypes = {
  code: PropTypes.string.isRequired,
  title: PropTypes.string,
  pageLoading: PropTypes.func,
};

export default WistiaVideo;
