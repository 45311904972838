import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { ContactUsStyle } from './contact-us-form.css';
import { Row, Col } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import { submitFormUrl, reCaptchaKey } from '../../constants/config';
import { FaTimesCircle } from 'react-icons/fa';
import { trackAnalytics } from '../../helpers/trackAnalytics';
import ReCAPTCHA from 'react-google-recaptcha';
import { MasterDataContext } from '../../store/masterDataContext';

class ContactUsForm extends PureComponent {
  static contextType = MasterDataContext;

  constructor(props) {
    super(props);

    this.state = {
      responseOk: false,
    };
    if (typeof window !== 'undefined') {
      window.recaptchaOptions = {
        useRecaptchaNet: true,
      };
    }
    this.recaptchaRef = React.createRef();
  }
  async formSubmit(values) {
    if (values.country.toLocaleLowerCase() === 'select country') {
      values.country = '';
    }
    let jsonObject = {
      TrialId: this.props.itemId,
      TrialTitle: this.props.title,
      From: values.firstName + ' ' + values.lastName,
      Email: values.email,
      Phone: values.phone,
      Country: values.country,
      Message: values.message,
      gRecaptcha: await this.recaptchaRef.current.executeAsync(),
    };

    if (this.props.locationName && this.props.locationName.length) {
      jsonObject.LocationName = this.props.locationName;
    } else {
      jsonObject.LocationName = '';
    }

    if (this.props.locationAddress && this.props.locationAddress.length) {
      jsonObject.LocationAddress = this.props.locationAddress;
    } else {
      jsonObject.LocationAddress = '';
    }

    trackAnalytics(
      'ContactUsSubmit',
      //Destructuring the jsonObject to select just the needed fields
      (({
        TrialId,
        TrialTitle,
        Country,
        Message,
        LocationName,
        LocationAddress,
      }) => ({
        TrialId,
        TrialTitle,
        Country,
        Message,
        LocationName,
        LocationAddress,
      }))(jsonObject)
    );

    if (typeof window !== 'undefined' && window.ga) {
      try {
        window.ga.getAll().forEach(_ga => {
          _ga.send('event', 'Mails', 'Mails', this.props.headerTitle);
        });
      } catch (ev) {
        console.log(ev);
      }
    }

    await fetch(submitFormUrl(), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonObject),
    })
      .then(response => {
        if (response.status === 204) {
          return {
            status: 'options',
          };
        }
      })
      .then(() => {
        this.setState({
          responseOk: true,
        });
      });
  }

  render() {
    let { data, headerTitle, hideModal, itemId, title } = this.props;
    trackAnalytics('ContactUsShow', {
      title: headerTitle,
      TrialId: itemId,
      TrialTitle: title,
    });

    return (
      <ContactUsStyle>
        <Row>
          <div className={'close-icon'}>
            <FaTimesCircle
              onClick={() => {
                hideModal();
              }}
            />
          </div>
          {this.state.responseOk ? (
            <div className="responseOk">
              <h2 className={'thankYouH2'}>Thank You!</h2>
              <p className={'thankYou'}>
                Your message has been successfully sent.
              </p>
            </div>
          ) : (
            <div className="form">
              <h2>{headerTitle}</h2>
              <p className={'studyTitle'}>
                <strong>Question for study {this.props.itemId}: </strong>
                {this.props.title}
                {this.props.locationName ? (
                  <div style={{ paddingTop: '10px' }}>
                    <strong>{this.props.locationName}</strong>
                    {this.props.locationAddress}
                  </div>
                ) : null}
                <figcaption>
                  Please fill out the form below. Fields marked with asterix (*)
                  are mandatory.
                </figcaption>
              </p>
              <div>
                <Formik
                  enableReinitialize={false}
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    country: '',
                    message: '',
                  }}
                  onSubmit={(values, actions) => {
                    actions.setSubmitting(true);
                    this.formSubmit(values);
                  }}
                  render={({
                    isSubmitting /*, handleChange, setFieldValue*/,
                  }) => (
                    <Form>
                      <Row>
                        <Col
                          className={'contact-form-input'}
                          lg={6}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <h3>
                            {data.searchResultJson.askToJoin.fields.firstName}{' '}
                            <sup>*</sup>
                          </h3>
                          <Field
                            className={'contact-us-input'}
                            type="text"
                            required="required"
                            minLength="2"
                            maxLength="100"
                            name="firstName"
                            placeholder={
                              data.searchResultJson.askToJoin.fields
                                .firstName_placeholder
                            }
                          />
                        </Col>
                        <Col
                          className={'contact-form-input'}
                          lg={6}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <h3>
                            {data.searchResultJson.askToJoin.fields.lastName}{' '}
                            <sup>*</sup>
                          </h3>
                          <Field
                            className={'contact-us-input'}
                            type="text"
                            name="lastName"
                            required="required"
                            minLength="2"
                            maxLength="100"
                            placeholder={
                              data.searchResultJson.askToJoin.fields
                                .lastName_placeholder
                            }
                          />
                        </Col>
                        <Col
                          className={'contact-form-input'}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <h3>
                            {data.searchResultJson.askToJoin.fields.email}{' '}
                            <sup>*</sup>
                          </h3>
                          <Field
                            className={'contact-us-input'}
                            type="email"
                            name="email"
                            required="required"
                            minLength="2"
                            maxLength="100"
                            placeholder={
                              data.searchResultJson.askToJoin.fields
                                .email_placeholder
                            }
                          />
                        </Col>
                        <Col
                          className={'contact-form-input'}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <h3>
                            {data.searchResultJson.askToJoin.fields.phone}
                          </h3>
                          <Field
                            className={'contact-us-input'}
                            type="text"
                            name="phone"
                            maxLength="100"
                            placeholder={
                              data.searchResultJson.askToJoin.fields
                                .phone_placeholder
                            }
                          />
                        </Col>
                        <Col
                          className={'contact-form-input country'}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <h3>
                            {data.searchResultJson.askToJoin.fields.country}{' '}
                          </h3>
                          <Field
                            className={'contact-us-input'}
                            component="select"
                            name="country"
                            required="required"
                          >
                            <option className={'selectCountry'}>
                              Select country
                            </option>
                            {this.context &&
                              this.context.masterData &&
                              this.context.masterData.COUNTRY.sort(
                                (a, b) => a.SortOrder - b.SortOrder
                              ).map(country => (
                                <option
                                  key={country.InternalValue}
                                  value={country.DisplayValue}
                                >
                                  {country.DisplayValue}
                                </option>
                              ))}
                          </Field>
                        </Col>
                        <Col className={'contact-form-input'} xs={12} md={12}>
                          <h3>
                            {data.searchResultJson.askToJoin.fields.message}{' '}
                            <sup>*</sup>
                          </h3>
                          <Field
                            className={'contact-us-input message'}
                            component="textarea"
                            name="message"
                            maxLength="5000"
                            required="required"
                            placeholder={
                              data.searchResultJson.askToJoin.fields
                                .message_placeholder
                            }
                          />
                        </Col>
                        <Col md={12} className={'privacy-notice'}>
                          <p>
                            By clicking on Send Message, you accept{' '}
                            <a
                              href={'https://www.gsk.com/en-gb/privacy-notice/'}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              GSK’s Privacy Statement
                            </a>
                            .
                          </p>
                        </Col>
                        <Col md={12} xs={12}>
                          <div
                            className="recaptcha-terms"
                            dangerouslySetInnerHTML={{
                              __html:
                                data.searchResultJson.askToJoin.recaptchaTerms
                                  .childMarkdownRemark.html,
                            }}
                          />
                        </Col>
                        <Col md={12} className={'submitBn-holder'}>
                          <button
                            type={'submit'}
                            disabled={isSubmitting}
                            className={'submit'}
                          >
                            {data.searchResultJson.askToJoin.fields.submit}
                            {isSubmitting ? '...' : ''}
                          </button>
                        </Col>
                      </Row>
                      <ReCAPTCHA
                        ref={this.recaptchaRef}
                        size="invisible"
                        sitekey={reCaptchaKey()}
                      />
                    </Form>
                  )}
                />
              </div>
            </div>
          )}
        </Row>
      </ContactUsStyle>
    );
  }
}

ContactUsForm.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  callback: PropTypes.func,
  contactUs: PropTypes.any,
  headerTitle: PropTypes.string,
  staticTitle: PropTypes.string,
  locationName: PropTypes.string,
  locationAddress: PropTypes.string,
  hideModal: PropTypes.func,
};

const ContactUsFormQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        searchResultJson {
          askToJoin {
            title
            paragraph1
            paragraph2
            paragraph3
            fields {
              firstName
              firstName_placeholder
              lastName
              lastName_placeholder
              email
              email_placeholder
              phone
              phone_placeholder
              country
              country_placeholder
              message
              message_placeholder
              terms
              terms_link_text
              terms_link_url
              submit
            }
            recaptchaTerms {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `}
    render={data => <ContactUsForm data={data} {...props} />}
  />
);

export default ContactUsFormQuery;
