import styled from 'styled-components';
import {
  activeColor,
  blueHover,
  buttonBG,
  fontColor,
  headerBorder,
  hoverColor,
  iconGrey,
  white,
} from '../../constants/theme';

export const ContactUsStyle = styled.div`
  position: relative;
  padding: 20px 20px 20px 20px;
  .row {
    width: auto;
  }
  .form {
    padding: 0 15px;
  }
  h2 {
    font-size: 28px;
    line-height: 36px;
    font-weight: bold;
    color: ${fontColor};
    padding-bottom: 20px;
    &.thankYouH2 {
      padding-bottom: 10px;
    }
  }
  .thankYou {
    color: #74705b;
    font-size: 16px;
    line-height: 24px;
  }
  h3 {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: ${fontColor};
    padding-top: 20px;
    padding-bottom: 8px;
    sup {
      left: -4px;
      top: -3px;
    }
  }
  .contact-us-input {
    width: 100%;
    height: 40px;
    border: 1px solid ${headerBorder};
    border-radius: 4px;
    padding-left: 16px;
    font-size: 14px;
    color: #212529;

    &.message {
      height: 120px;
      padding-top: 16px;
      line-height: 24px;
      box-shadow: none;
    }
    &::placeholder {
      opacity: 0.3;
      color: #212529;
    }
    &:focus {
      border: 1px solid ${buttonBG};
      outline: none;
    }
  }
  &:focus {
    border: 1px solid ${iconGrey};
    outline: none;
  }
  .close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    svg {
      font-size: 23px;
      color: ${activeColor};
      cursor: pointer;
    }
  }
  .studyTitle {
    font-size: 16px;
    line-height: 24px;
    color: ${fontColor};
    strong {
      font-weight: bold;
    }
    figcaption {
      font-size: 12px;
      line-height: 17px;
      color: ${iconGrey};
      padding-top: 10px;
    }
  }
  .country {
    width: 100%;
    select {
      background-color: ${white};
      font-size: 14px;
      color: #212529;
      option {
        color: ${fontColor};
      }
    }
  }
  .recaptcha-terms {
    margin-bottom: 30px;
    p {
      font-size: 12px;
      a {
        color: ${buttonBG};
        &:hover {
          text-decoration: none;
          color: ${blueHover};
        }
      }
    }
  }
  .submitBn-holder {
    justify-content: flex-end;
    display: flex;
    .submit {
      height: 40px;
      border: 1px solid ${activeColor};
      background-color: ${activeColor};
      border-radius: 4px;
      color: white;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      padding: 0 16px;
      &:hover {
        background-color: ${hoverColor};
      }
    }
  }
  .privacy-notice {
    font-size: 12px;
    line-height: 16px;
    padding: 10px 15px 30px 15px;

    color: ${fontColor};
    a {
      color: ${buttonBG};
      &:hover {
        text-decoration: none;
        color: ${blueHover};
      }
    }
  }

  .form-conditions {
    color: ${activeColor};
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 20px 20px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .search-button {
      margin-top: 10px;
    }
  }
`;
